<script>
  /**
   * A postal code input.
   */
  export default {
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      /** The input’s value. */
      value: {
        type: String,
        default: '',
      },
      /** The ISO code of the country that should be used to validate postal codes. When left `undefined`, the country is determined automatically based on the `value`. */
      countryIso: {
        type: String,
        default: undefined,
        validator: (value) => ['US', 'CA'].includes(value),
      },
    },
    data() {
      return {
        dataByCountry: {
          US: {
            regex: /^\d{5}(-\d{4})?$/,
            mask: '00000-0000',
            maskDefinitions: {},
          },
          CA: {
            regex: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( ){1}\d[ABCEGHJKLMNPRSTVWXYZ]\d$/,
            mask: '### ###',
            maskDefinitions: {
              '#': /[a-zA-Z0-9]/,
            },
          },
        },
      }
    },
    computed: {
      finalCountryIso() {
        if (this.countryIso !== undefined) {
          return this.countryIso
        }
        if (!this.value) {
          return undefined
        }
        if (this.value.match(/^[ABCEGHJKLMNPRSTVXY]/)) {
          return 'CA'
        }
        return 'US'
      },
      regex() {
        return this.dataByCountry[this.finalCountryIso]?.regex
      },
      mask() {
        if (this.countryIso === undefined) {
          return [
            {
              mask: this.dataByCountry.US.mask,
              definitions: this.dataByCountry.US.maskDefinitions,
            },
            {
              mask: this.dataByCountry.CA.mask,
              definitions: this.dataByCountry.CA.maskDefinitions,
            },
          ]
        }
        return this.dataByCountry[this.finalCountryIso]?.mask
      },
      maskDefinitions() {
        if (this.countryIso === undefined) {
          return undefined
        }
        return this.dataByCountry[this.finalCountryIso]?.maskDefinitions
      },
      propsToPassDown() {
        const { countryIso, ...propsToPassDown } = this.$props
        return propsToPassDown
      },
      listeners() {
        const { input, ...listeners } = this.$listeners
        return listeners
      },
    },
    methods: {
      input($event) {
        /** Emitted when the input’s value changes. */
        this.$emit('input', ($event ?? '').toUpperCase())
      },
    },
  }
</script>

<template>
  <BaseInput
    type="text"
    :pattern="regex"
    :custom-messages="{ regex: 'Please enter a valid postal code.' }"
    :mask="mask"
    :mask-definitions="maskDefinitions"
    v-bind="{ ...propsToPassDown, ...$attrs }"
    v-on="listeners"
    @input="input"
  >
    <!-- @slot The input’s label. Should contain text. Defaults to “Postal Code”. -->
    <slot>Postal Code</slot>
    <template v-slot:icons>
      <!-- @slot Optional. Icon(s) to display on the right end of the input. Should contain one or multiple `BaseIconInput` component(s). -->
      <slot name="icons" />
    </template>
  </BaseInput>
</template>
